import React, { useMemo } from 'react'
import { Link, LinkProps } from '../link/link'
import { ButtonProps } from '../button/button'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DoubleTriangleSvg } from './DoubleTriangle'
import {
  StyledButton,
  StyledButtonContainer,
  StyledDoubleTriangle,
  StyledHorizontalHeadingSection,
  StyledImageBackground,
  StyledTitle,
  StyledTrapezoid,
  StyledWrapper,
} from './StyledSimpleCta'

type SimpleCtaHeadingSectionProps = {
  children: Array<React.ReactNode>
}


const SimpleCTAHeadingSection: React.FC<SimpleCtaHeadingSectionProps> = ({ children, ...props }) => {
  return (
    <StyledHorizontalHeadingSection item md={4} xs={12} {...props}>{children}</StyledHorizontalHeadingSection>
  )
}

type SimpleCtaTitleProps = {
  //
}

const SimpleCTATitle : React.FC<SimpleCtaTitleProps> = (props) => {
  return (
    <StyledTitle {...props}>
      {props.children}
    </StyledTitle>
  )
}

type SimpleCtaLinkProps = {
  //
} & LinkProps

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <StyledButton {...props} innerRef={ref}>
    {props.children}
  </StyledButton>),
)

const SimpleCTALink: React.FC<SimpleCtaLinkProps> = ({ href, ...props }) => {
  return (
    <StyledButtonContainer md={2} xs={12}>
      <Link href={href} { ...props} passHref>
        <ButtonLink primary buttonColor="black">{props.children}</ButtonLink>
      </Link>
    </StyledButtonContainer>
  )
}

type SimpleCTAWrapperProps = {
  dimension?: { w: number; h: number }
}

const SimpleCTAWrapper: React.FC<SimpleCTAWrapperProps> = ({ dimension, ...props }) => {
  if (!dimension) {
    return null
  }

  return (
    <StyledWrapper {...props} tabIndex={0}>
      <StyledImageBackground>
        <StyledTrapezoid>{props.children}</StyledTrapezoid>
      </StyledImageBackground>
      <StyledDoubleTriangle>
        <DoubleTriangleSvg
          width={dimension.w}
          height={dimension.h}
        />
      </StyledDoubleTriangle>
    </StyledWrapper>
  )
}

export type SimpleCtaProps = { section?: boolean; title?: boolean; cta?: boolean; href?: string } & (
  // Heading container
  | ({ section: true } & SimpleCtaHeadingSectionProps)
  // Title
  | ({ title: true } & SimpleCtaTitleProps)
  // Button
  | ({ cta: true; href: string } & SimpleCtaLinkProps)
  // Wrapper
  | SimpleCTAWrapperProps
)

export const SimpleCta: React.FC<SimpleCtaProps> = ({ section, title, cta, href, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const dimension = useMemo(() => {
    if (isMobile) {
      return { w: 81, h: 103 }
    }

    return { w: 142, h: 180 }
  }, [isMobile])

  if (section) {
    return (
      <SimpleCTAHeadingSection {...props}>
        {props.children}
        <React.Fragment></React.Fragment>
      </SimpleCTAHeadingSection>
    )
  }

  if (title) {
    return (
      <SimpleCTATitle {...props}>
        {props.children}
      </SimpleCTATitle>
    )
  }


  if (cta) {
    return (
      <SimpleCTALink href={href || '#'} { ...props}>
        {props.children}
      </SimpleCTALink>
    )
  }  

  return <SimpleCTAWrapper dimension={dimension}>{props.children}</SimpleCTAWrapper>
}