import React, { ComponentProps } from 'react'
import { Grid } from '@material-ui/core'

export type QuickLinksWrapperProps =  Omit<ComponentProps<typeof Grid>, 'direction'> & {
  //
}

export const QuickLinksWrapper: React.FC<QuickLinksWrapperProps> = ({  container = true, alignContent = 'center', justifyContent = 'flex-start', children, ...props }) => {
  return (
    <Grid container={container} direction="row" alignContent={alignContent} justifyContent={justifyContent} { ...props }>
      { children }
    </Grid>
  )
}
