import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Grid, Typography, useTheme } from '@material-ui/core'
import Image from 'next/image'
import { Button } from '../button/button'
import { TriangleSvg } from './element/TriangleSvg'
import { Rotator } from './element/Rotator'
import { getDimensions } from '../../utils/getDimensions'

export type HomeBannerProps =
    // Image
    {image:true; title?: false; subtitle?: false; cta?: false; href?: never; src: string; text?:never}
    // Title
    | {image?:false; title: true; subtitle?: false; cta?: false; href?: never; src?: never; text:string}
    // Subtitle 
    | {image?:false; title?: false; subtitle: true; cta?: false; href?: never;src?: never; text:React.ReactNode}
    // CTA
    | {image?:false; title?: false; subtitle?: false; cta: true; href: string; src?: never; text:string}


const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(30)};
  font-weight: 500;
  margin-top: ${props => props.theme.typography.pxToRem(30)};
  opacity: 1;
  line-clamp: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: 0;
    font-size: ${props => props.theme.typography.pxToRem(45)};
    font-weight: 500;
    max-width: ${props => props.theme.typography.pxToRem(426)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: 100%;
    text-align: left;
    max-width: ${props => props.theme.typography.pxToRem(300)};
  }

  ${props => props.theme.breakpoints.down('xs')} {
    width: 70%;
    text-align: center;
  }
`

const StyledSubtitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(17)};
  margin-top: ${props => props.theme.typography.pxToRem(30)};
  max-width: ${props => props.theme.typography.pxToRem(426)};
  line-clamp: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: center;
  display: none;
  width: 70%;
  ${props => props.theme.breakpoints.up('sm')} {
    display: block;
    width: 90%;
    text-align: left;
    line-height: ${props => props.theme.typography.pxToRem(35)};
  }
`

const StyledButton =styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-top: ${props => props.theme.typography.pxToRem(30)};
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`
const StyledClearFix = styled.div<{$height:number}>`
  height: ${props => props.theme.typography.pxToRem(props.$height)};
  ${props => props.theme.breakpoints.up('md')} {
    height: ${props => props.theme.typography.pxToRem(props.$height*1.1)};
  }

  max-height: ${props => props.theme.typography.pxToRem(890)};
`

const ImageBox = styled.div<{$width:number; $height:number}>`
  position: absolute;
  right: 0;
  width: calc(100% - ${props => props.theme.typography.pxToRem(40)});
  overflow: hidden;
  padding-bottom: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    overflow: visible;
    position: absolute;
    top: 0;
    right: unset;
    width: ${props => props.theme.typography.pxToRem(props.$width*1.1)};
    max-width: ${props => props.theme.typography.pxToRem(991)};
    max-height: ${props => props.theme.typography.pxToRem(890)};
  }
`

const StyledContent =styled(Grid)`
  align-items: center;
  margin-top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(20)};
    margin-top: ${props => props.theme.typography.pxToRem(100)};
    align-items: flex-start;
    width: ${props => props.theme.typography.pxToRem(485)};
    max-height: ${props => props.theme.typography.pxToRem(890)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin: 0;
    padding: 0;
  }
`
const BannerContainer = styled(Grid)`
  display: flex;
  max-width: ${props => props.theme.typography.pxToRem(1650)};
  margin-left: auto;
`

const BannerImageContainer = styled(Grid)`
  display: inline-block;
`

const StyledImage = styled(Rotator)<{$width: number; $height: number}>`
  transition-duration: 0.4s;
  transition-property: transform;
  width: ${props => props.theme.typography.pxToRem(props.$width)};
  height: ${props => props.theme.typography.pxToRem(props.$height)};
  max-width: ${props => props.theme.typography.pxToRem(990)};

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(props.$width*1.1)};
    height: ${props => props.theme.typography.pxToRem(props.$height*1.1)};
  }
`

const StyledLargeTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(-5deg);
  left: 15%;
  width: 12%;
  top: 72%;

`

const StyledMediumTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(20deg);
  left: 80%;
  top: 70%;
  width: 8%;

`

const StyledSmallTriangle = styled(Rotator)`
  position: absolute;
  transition-duration: 0.4s;
  transform: rotate(39deg);
  left: 80%;
  width: 6%;
  top: 46%;

`

export const useContainerDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(ref))
    }

    if (ref.current) {
      setDimensions(getDimensions(ref))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  dimensions.height = dimensions.width * 890 / 991

  return dimensions
}

export const HomeBanner: React.FC<HomeBannerProps> = ({ image, title, subtitle, cta, href, src, text, ...props }) => {
  const  theme = useTheme()

  const useDimensionRef = useRef() as MutableRefObject<HTMLDivElement>
  const { width, height } = useContainerDimensions(useDimensionRef)

  if(title){
    return (
      <StyledTitle variant="h2">
        {text}
      </StyledTitle>      
    )
  }

  if(subtitle){
    return (
      <StyledSubtitle variant="body1">        
        {text}
      </StyledSubtitle>
    )
  }

  if(cta){
    if(href){
      return (
        <StyledButton primary buttonColor="orange" href={href}>{text}</StyledButton>
      )
    }

    return (      
      <StyledButton primary buttonColor="orange">{text}</StyledButton>
    )
  }

  if(image){
    return (
      <BannerContainer {...props} container direction="row-reverse" justifyContent="center" >
        <BannerImageContainer ref={useDimensionRef} container item justifyContent="center" alignItems="flex-start" xs={12} sm={8} md={8}>
          <ImageBox $width={width} $height={height}>
            {src && (
              <StyledImage $width={width} $height={height} rg={0} ypx={60} >
                <Image src={src} alt="" layout="fill" objectFit="contain" objectPosition="top right" />
              </StyledImage>
            )}
            <StyledLargeTriangle rg={15} ypx={20}><TriangleSvg color={theme.palette.primary.main}/></StyledLargeTriangle>
            <StyledMediumTriangle rg={30} ypx={20}><TriangleSvg color={theme.palette.secondary.main}/></StyledMediumTriangle>
            <StyledSmallTriangle rg={-5} ypx={20}><TriangleSvg color={theme.palette.secondary.light}/></StyledSmallTriangle>
          </ImageBox>
          <StyledClearFix $height={height} />
        </BannerImageContainer>
        <StyledContent container direction="column" justifyContent="center"  alignItems="flex-start" item xs={12} sm={4} md={4} spacing={0}>        
          {props.children}
        </StyledContent>
      </BannerContainer>
    )
  }

  return null
}