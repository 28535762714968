import React from 'react'
import { NewsFragment } from './__generated__/news'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { StyledHeading } from '../styledHeading'
import { nonNull } from '../../../utils/nonNull'
import { DEFAULT_NEWS_IMAGE } from '../../../constants/defaultImages'
import { truncate } from '../../../utils/truncate'
import { gql } from '@apollo/client/core'

export type NewsProps = NewsFragment

export const News: React.FC<NewsProps> = ({ entries }) => {
  if (!entries?.length ?? 0 > 0) {
    return null
  }

  return (
    <>
      <StyledHeading variant="h5" align="center">
        Latest News Articles
      </StyledHeading>
      <CardDesignCarousel>
        {nonNull(entries).map((item, index) => {
          if( item.__typename === 'news_newsEntryType_Entry') {
            const url = item.newsDetailBanner?.[0]?.bannerImage?.[0]?.url

            return (
              <CardDesignItem key={index} href={item.uri || '#'}>
                <CardDesignItem image src={url ? url : DEFAULT_NEWS_IMAGE} alt={''} layout="fill" objectFit="cover" objectPosition="center" />
                <CardDesignItem date>{new Date(item.postDate).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
                <CardDesignItem title>{item?.title ? truncate(item.title, 75) : 'Untitled'}</CardDesignItem>
              </CardDesignItem>
            ) 
          }

          return null
        })}
      </CardDesignCarousel>
    </>
  )
}

export const NEWS_FRAGMENT = gql`
  fragment NewsFragment on Query {
    entries(section: "news", orderBy: "postDate DESC", limit: 6) {
      ... on news_newsEntryType_Entry {
        postDate
        title
        uri
        newsDetailBanner {
          ... on newsDetailBanner_BlockType {
            id
            bannerImage{
              ...ImageFragment
            }
          }
        }
      }
    }
  }
`
