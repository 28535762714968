import React from 'react'
import { Grid } from '@material-ui/core'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import {
  StyledCardContainer,
  StyledCardIconContainer,
  StyledCardList,
  StyledTitleCheveron,
  StyledTitleText,
  StyledQuickLink,
} from './styledQuickLinksCard'
import { LinkProps } from '../link/link'

export type QuickLinksCardProps =  React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
  title: string
  iconCode: IconCodeType
}

export const QuickLinksCard: React.FC<QuickLinksCardProps> = ({ title, iconCode, href, children, item=true, xs=12, sm=4, ...props }) => {
  return (
    <StyledCardContainer item={item} xs={xs} sm={sm} {...props}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <StyledCardIconContainer item>
          <CreditUnionIcon width={49} height={49} iconCode={iconCode}/>
        </StyledCardIconContainer>
        <StyledQuickLink container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
          <StyledTitleText rel="nooppener noreferrer" target="_blank">{ title }</StyledTitleText>
          <StyledTitleCheveron icon={faChevronRight}/>
        </StyledQuickLink>
      </Grid>
      <Grid container>
        <StyledCardList>
          {children}
        </StyledCardList>
      </Grid>
    </StyledCardContainer>
  )
}